.main-page .my-link {
  font-family: var(--font-mulish);
  font-size: 16px;
  font-weight: 700;
  padding: 12px 23px;
  border-radius: 8px;
  display: inline-block;
  width: initial;
}
/* MEDIA QUERIS */
@media (max-width: 576px) {
  .main-page .ant-row {
    display: block;
  }
}
