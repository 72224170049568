.errorMessage{
    color: red;
}


.wn-btn-pay {
    background-color: var(--mv-blue) !important;
    border-color: var(--mv-blue) !important;
    color: var(--mv-white) !important;
    padding: 5px 10px;
    border-radius: 5px;
    margin-top: 5px;
    cursor: pointer;
  }
