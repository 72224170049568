.stacked-chart {
    margin: 0px;
}

.stacked-chart h5.ant-typography {
    font-family: var(--font-sfpro);
    font-size: 24px;
    font-weight: 600;
    color: var(--color-dark);
}

.stacked-chart .apexcharts-legend.apx-legend-position-top.apexcharts-align-left {
    justify-content: flex-end;
}

.jump-to {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    position: relative;
    z-index: 9;
}

.jum-strain {
    display: flex;
    width: 100%;
}

.jump-to label {
    margin-right: 10px;
    margin-top: 5px;
}

.csv-visual-preview {
    margin-top: -50px;
}

.stacked-chart .apexcharts-legend.apx-legend-position-top.apexcharts-align-left {
    max-width: 650px;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    justify-content: flex-start;
}

.apexcharts-legend.apexcharts-align-left .apexcharts-legend-series {
    display: flex !important;
    align-items: center !important;
}

.apexcharts-xaxis-annotations {
    position: relative;
}

.apexcharts-xaxis-annotations line:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 5px;
    border-radius: 10px;
    background-color: #363636;
}

.apexcharts-xaxis-annotations line:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 5px;
    border-radius: 10px;
    background-color: #363636;
}

.hide {
    display: none;
}

@media(max-width: 1199px) {
    .stacked-chart .apexcharts-legend.apx-legend-position-top.apexcharts-align-left {
        max-width: 600px;
    }
}

@media(max-width: 1024px) {
    .stacked-chart .apexcharts-legend.apx-legend-position-top.apexcharts-align-left {
        max-width: 500px;
    }
}

@media(max-width: 767px) {
    .stackCharOverflow > div {
        /* Styles for the next div element */
        /* For example, adding a background color */
       /* width: 100%;
       overflow: hidden !important;
       overflow-x: scroll !important; */
      }
    .csv-visual-preview {
        margin-top: 0px;
        margin-left: -5px;
        margin-right: -5px;
    }

    .stacked-chart .apexcharts-legend.apx-legend-position-top.apexcharts-align-left {
        /* max-width: 100%; */
    }

    .stacked {
        width: 100% !important;
        height: 100%;
        overflow-y: hidden;
        overflow-x: auto;
        margin-top: 0px !important;

    }

    .stacked .apexcharts-tooltip {
        top: -190.431px;
        margin-top: 190px;
        /* z-index: 10000; */
        /* display: contents; */
    }

    .hide {
        display: block;
    }

    .stacked-chart {
        margin: -30px 0 20px;
    }

}

.sidebar-stacked {
    display: none;
}

.activebar {
    display: none;
}

.legend-sidebar {
    display: none;
}

@media(max-width: 567px) {
    .sidebar-stacked {
        height: 35px;
        /* width: 145px; */
        background: #2f87ef;
        border-radius: 6px;
        float: right;
        /* position: fixed; */
        top: 14px;
        z-index: 1;
        right: 9px;
        padding: 7px;
        color: #ffff;
        text-align: center;
        cursor: pointer;
        display: flex;
    }

    .stacked .apexcharts-tooltip {
        margin-top: 70px !important;
        max-height: 180px !important;
    }

    .effect {
        color: #fff;
        margin-left: 7px;
        font-weight: 600;
    }
    .jum-strain {
        display: flex;
        width: 100%;
    }
    .legend-sidebar {
        display: block !important;
        position: fixed;
        top: 65px;
        right: 0;
        /* max-width: 320px;
        min-width: 320px; */
        height: 70vh;
        background-color: var(--color-white);
        padding: 10px 15px 0;
        transition: 0.3s all;
        transform: translateX(0);
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        -webkit-transition: 0.3s all;
        -moz-transition: 0.3s all;
        -ms-transition: 0.3s all;
        -o-transition: 0.3s all;
        box-shadow: 0px 0 10px 5px rgba(0, 0, 0, 0.03);
        overflow: auto;


    }

    .activebar {
        display: block
    }

    .inActive {
        display: none;
    }

    .legends {
        width: 100%;
        height: auto;
    }

    .legendText {
        font-size: 12px;
        margin-right: 5px;
    }

    .legendcolor {
        display: inline-block;
        height: 11px;
        width: 11px;
        border-radius: 15px;
        margin-right: 2px;
    }

    .effectClass {
        font-size: 10px;
        height: auto;
        padding: 5px;
    }

}

.legend-sidebar {
    display: block !important;
    margin-bottom: 10px;
    position: relative;
    top: 0px;
    height: auto;
    float: right;
    /* max-width: 53%; */
    padding: 10px 15px 0;
    transition: 0.3s all;
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    -webkit-transition: 0.3s all;
    -moz-transition: 0.3s all;
    -ms-transition: 0.3s all;
    -o-transition: 0.3s all;
}

.legends {
    width: 100%;
    /* height: auto; */
}

.effectClass {
    margin-right: 5px;
    background: #1c7aff;
    color: #fff
}

.legendText {
    font-size: 12px;
    margin-right: 5px;
}

.legendcolor {
    display: inline-block;
    height: 11px;
    width: 11px;
    border-radius: 15px;
    margin-right: 2px;
}

.stacked {
    /* width: 100% !important;
    overflow-y: auto;
    overflow-x: auto; */

}

.stacked .apexcharts-toolbar {
    /* position: fixed;
    top: -38px !important;
    left: 117px !important
 */
}

.stacked .apexcharts-tooltip {
    margin-top: 50px;
    pointer-events: unset;
    overflow: scroll;
    height: auto;
    max-height: 250px;
    opacity: 1;
}

.custom-marker-stacked {
    position: absolute;
    display: none;
}

.apexcharts-xaxis-label {
    height: auto;
}


/* .inActive {
    display: none;
} */

/* .apexcharts-tooltip {
    height: 500px;
    overflow: scroll;
  } */

  .highcharts-credits{
    display: none !important;
  }

