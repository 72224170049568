.view-orders h3.ant-typography {
  font-family: var(--font-poppins);
  font-size: 24px;
  font-weight: 600;
  color: var(--color-dark);
  margin-bottom: 0;
}

.view-orders {
  display: flex;
  margin-top: 20px;
}

.table-selects {
  display: flex;
  align-items: center;
}

.sort-selects {
  margin-left: auto;
}

.show-select {
  margin-left: 30px;
}

.show-select .ant-typography,
.sort-selects .ant-typography {
  font-family: var(--font-mulish);
  font-size: 16px;
  font-weight: 400;
  color: var(--color-dark-gray);
  margin-right: 5px;
}

.view-orders .ant-select-single .ant-select-selector {
  border: 0;
  padding: 0 10px 0 10;
}

.user-manage-table-header .ant-select-dropdown .ant-select-item-option {
  text-align: center;
}

.view-orders
  .ant-select-single.ant-select-show-arrow
  .ant-select-selection-item {
  padding-inline-end: 22px;
}

.view-orders
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
    .ant-pagination-size-changer
  ):hover
  .ant-select-selector {
  border-color: transparent;
  box-shadow: none;
}

.view-orders .ant-input {
  height: 40px;
  background: transparent;
  border: 1px solid var(--color-text);
  border-radius: 10px;
  padding-right: 45px;
}

.view-orders
  .ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  .ant-input-search-button {
  height: 50px;
  color: var(--color-text);
}

.view-orders .ant-input-search .ant-input:focus,
.view-orders .ant-input-search .ant-input:hover,
.view-orders .ant-input-affix-wrapper:hover,
.view-orders .ant-input-affix-wrapper,
.view-orders .ant-btn-default,
.view-orders .ant-btn-default:hover {
  border-color: var(--color-text);
}

.view-orders .ant-input:focus,
.view-orders .ant-input-affix-wrapper {
  box-shadow: none;
}

.view-orders-table {
  margin-top: 30px;
}

.view-orders-table .ant-table .ant-table-thead .ant-table-cell {
  color: var(--color-dark);
}

.view-orders-table .ant-table .ant-table-cell {
  font-family: var(--font-poppins);
  font-size: 12px;
  font-weight: 600;
  color: var(--color-gray-600);
  text-align: center;
}

.view-orders-table .ant-table .ant-table-cell .ant-typography {
  width: 100px;
  padding: 5px 0 6px;
}

.view-orders-table
  .ant-table-wrapper
  .ant-table-container
  table
  > thead
  > tr:first-child
  > *:first-child {
  text-align: left;
}

.view-orders-table
  .ant-table-wrapper
  .ant-table-container
  table
  > tbody
  > tr:first-child
  > *:first-child {
  text-align: left;
}

.view-orders-table a {
  font-family: var(--font-poppins);
  font-size: 14px;
  font-weight: 700;
  border: 0;
  border-radius: 10px;
  box-shadow: none;
  padding: 4px 12px 5px;
  display: inline-block;
}

/* MEDIA QUERIES */
@media (max-width: 767px) {
  .view-orders {
    flex-direction: column;
  }

  .table-selects {
    flex-direction: column;
  }

  .sort-selects {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 576px) {
  .view-orders .ant-input-group-wrapper {
    width: 100% !important;
    margin-left: 0 !important;
  }
}
