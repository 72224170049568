.pdf-list-data .ant-list-bordered {
  border: 1px solid #D9E0E9;
}

.pdf-list-data h3.ant-typography {
  font-family: var(--font-poppins);
  font-size: 24px;
  font-weight: 600;
  color: var(--color-dark);
}

.pdf-list-data h3.ant-typography:nth-child(2) {
  margin-top: 20px;
}

.list-header {
  padding: 20px 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #D9E0E9;
}

.pdf-list-data h5.ant-typography {
  font-family: var(--font-mulish);
  font-size: 20px;
  font-weight: 700;
  color: var(--color-text);
  margin-right: 30px;
  margin-bottom: 0;
}

.pdf-list-data
  .ant-list
  .ant-list-item
  .ant-list-item-meta
  .ant-list-item-meta-title
  > a {
  color: var(--color-blue);
}

.pdf-list-data
  .ant-list
  .ant-list-item
  .ant-list-item-meta
  .ant-list-item-meta-title {
  margin-bottom: 0;
  margin-left: 5px;
}

.list-header .ant-btn {
  padding: 3px 10px;
}

.pdf-list-data .ant-list .ant-list-item:nth-child(odd) {
  background: var(--color-gray-400);
}

.pdf-list-data .ant-list-footer a {
  font-family: var(--font-mulish);
  font-size: 16px;
  font-weight: 600;
  color: var(--color-text);
  background: transparent;
}

.pdf-list-data svg {
  margin-left: 20px;
}
