.user-manage-table-header {
  margin-top: 20px;
  display: flex;
}

.user-manage-table-header h3.ant-typography {
  font-family: var(--font-poppins);
  font-size: 24px;
  font-weight: 600;
  color: var(--color-dark);
}

.table-selects {
  display: flex;
}

.sort-selects {
  margin-left: auto;
}

.show-select {
  margin-left: 30px;
}

.user-management-table {
  margin-top: 30px;
}

.user-manage-table-header .ant-select-dropdown .ant-select-item-option {
  text-align: center;
}

.show-select .ant-typography,
.sort-selects .ant-typography {
  font-family: var(--font-mulish);
  font-size: 16px;
  font-weight: 400;
  color: var(--color-dark-gray);
  margin-right: 5px;
}

.user-manage-table-header .ant-select-single .ant-select-selector {
  border: 0;
  padding: 0 10px 0 10px;
}

.user-manage-table-header
  .ant-select-single.ant-select-show-arrow
  .ant-select-selection-item {
  padding-inline-end: 22px;
}

.user-manage-table-header
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
    .ant-pagination-size-changer
  ):hover
  .ant-select-selector {
  border-color: transparent;
  box-shadow: none;
}

.user-management-table .ant-table .ant-table-cell {
  font-family: var(--font-poppins);
  font-size: 12px;
  font-weight: 600;
  color: var(--color-gray-600);
  text-align: left;
}

.user-management-table .ant-table .ant-table-thead .ant-table-cell {
  color: var(--color-dark);
}

.user-management-table .ant-table .ant-table-cell .ant-btn {
  font-weight: 700;
  color: var(--color-text);
  background: var(--color-light-gray);
  border: 0;
  border-radius: 10px;
  box-shadow: none;
  padding: 3px 12px 4px;
  height: initial;
}

.user-management-table a {
  font-family: var(--font-poppins);
  font-size: 14px;
  font-weight: 700;
  color: var(--color-text);
  background: var(--color-gray-100);
  border: 0;
  border-radius: 10px;
  box-shadow: none;
  padding: 4px 12px 5px;
  display: inline-block;
}
