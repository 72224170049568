/* .user-pricing-title h1.ant-typography {
  margin-top: 0px;
  margin-bottom: 0;
  line-height: 45px;
}

.global-discount {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.custom-pricing-left {
  flex: 0 0 30%;
  max-width: 30%;
}

.custom-pricing-header {
  display: flex;
  flex-wrap: nowrap;
}

.custom-pricing-header h2 {
  font-size: 24px;
  font-weight: 700;
  color: #000;
}

.custom-pricing-header .ant-form-item {
  margin-bottom: 0;
  width: 60px;
  margin-left: 10px;
}

.custom-pricing-header .ant-form-item .ant-input {
  height: 33px;
  border-radius: 0;
  border: none;
  background: #FDFFA3;
  color: #000;
  font-family: inherit;
  font-size: 20px;
  font-weight: 700;
  opacity: 0.6000000238418579;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}

.custom-pricing-right {
  flex: 0 0 70%;
  display: flex;
  flex-wrap: nowrap;
  gap: 12px;
}

.custom-pricing-card-outer {
  flex: 1;
}

.custom-pricing-card {
  background-color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.custom-pricing-colored-outer {
  padding: 8px 8px 0px 8px;
}

.custom-pricing-colored {
  text-align: center;
  height: 66px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: white;
}

.custom-pricing-colored h2 {
  line-height: normal;
  font-size: 22px;
  font-weight: 700;
  color: white;
}

.bg-orange {
  background-color: #FBB703;
}

.bg-green {
  background-color: #5FD029;
}

.bg-purple {
  background-color: #57268d;
}

.bg-blue {
  background-color: #1C69C2;
}

.bg-pink {
  background-color: #BB1F89;
}

.custom-pricing-body {
  display: flex;
  margin-top: -1px;
}

.custom-pricing-body h2 {
  color: #000;
  font-size: 18px;
  font-weight: normal;
  opacity: 0.6000000238418579;
  position: relative;
  padding-left: 14px;
}

.custom-pricing-body h2:before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #000;
  opacity: 0.6000000238418579;
  width: 4px;
  height: 4px;
  border-radius: 10px;
}

.custom-pricing .custom-pricing-body:nth-child(even) .custom-pricing-left h2 {
  font-weight: 700;
}

.custom-pricing-body .custom-pricing-card {
  border-radius: 0;
} */

/* CUSTOM PRICING STYLE */
.custom-pricing {
  margin-top: 20px;
}

.custom-pricing {
  background: var(--color-bg-blue);
  padding: 30px 20px;
  margin-top: 20px;
  border-radius: 25px;
  /* overflow-x: scroll; */
  overflow-x: auto;
  white-space: nowrap;
}

.custom-pricing h2.ant-typography {
  font-family: var(--font-sfpro);
  font-size: 20px;
  font-weight: 700;
  color: var(--color-dark);
  margin-bottom: 25px;
}

.discount-price {
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 20px;
}

.discount-price .ant-typography {
  display: block;
  font-family: var(--font-sfpro);
  font-size: 20px;
  font-weight: 700;
  color: var(--color-light-black);
}

.discount-price .ant-typography:nth-child(2) {
  font-weight: bold;
}

.discount-price .ant-input {
  font-family: var(--font-sfpro);
  font-size: 20px;
  font-weight: 600;
  max-width: 60px;
  width: 100%;
  height: 32px;
  background: #fdffa3;
  padding: 5px 7px;
  color: var(--color-dark);
  margin-left: 6px;
  border: 0;
  box-shadow: none;
  text-align: center;
}

.custom-pricing .ant-list-item .ant-typography {
  display: block;
  font-family: var(--font-sfpro);
  font-size: 18px;
  color: var(--color-light-black);
  margin-left: 18px;
}

.custom-pricing .ant-list-item {
  position: relative;
}

.custom-pricing .ant-list-item::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 4px;
  height: 4px;
  border-radius: 30px;
  background: var(--color-light-black);
}

.custom-pricing .ant-list-item:nth-child(odd) {
  font-weight: 700;
}

.custom-pricing .ant-list-item:nth-child(even) {
  font-weight: 400;
}

.pricing-content {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.pricing-content .ant-input {
  font-family: var(--font-sfpro);
  font-size: 18px;
  font-weight: 700;
  color: white;
  border: 0;
  box-shadow: none;
  text-align: center;
  padding: 0;
  background: none;
}

.pricing-content .ant-input::placeholder {
  color: white;
}

.pricing-content .ant-input-number {
  font-family: var(--font-sfpro);
  font-size: 18px;
  font-weight: 700;
  color: var(--color-dark);
  border: 0;
  box-shadow: none;
  text-align: center;
  padding: 0;
  background: none;
  text-align: center;
}

.pricing-content .ant-list {
  background: var(--color-white);
  border-radius: 10px;
  flex: 1;
  margin-right: 10px;

  /* overflow: hidden; */
}

.pricing-content .ant-list .ant-spin-nested-loading {
  width: 100px;
}

.pricing-content .ant-list:last-child {
  margin-right: 0;
}

.pricing-content .ant-list .ant-list-item {
  padding: 11px 0 12px;
}

.pricing-content .ant-list .ant-list-item:first-child {
  padding: 10px 10px;
  margin: 10px 10px 0;
  margin-bottom: 26px;
}

.pricing-content .ant-list-item {
  margin-left: 0;
  font-size: 18px;
  font-weight: bold;
  color: var(--color-dark);
  text-align: center;
  justify-content: center;
}

.pricing-content .ant-list-item {
  padding: 13px 0;
}

.pricing-content .ant-list-item:nth-child(even) {
  font-weight: bold;
}

.pricing-content .ant-list-item::after {
  background: transparent;
  width: initial;
}

.pricing-content .ant-list-item:nth-child(1) {
  border-radius: 8px;
  display: block;
}

.pricing-content .ant-list-item:nth-child(1) .ant-typography {
  font-family: var(--font-sfpro);
  font-size: 15px;
  font-weight: 700;
  color: var(--color-white);
  margin-left: 0;
  text-align: center;
}

.pricing-content .ant-list-item .ant-typography {
  font-size: 18px;
  margin-left: 0;
}

.discount-setting .ant-list:not(:first-child) {
  margin-left: 30px;
}

.discount-setting .ant-list .ant-list-item::after {
  width: initial;
  background: transparent;
}

.custom-pricing h5.ant-typography {
  font-family: var(--font-sfpro);
  font-size: 18px;
  font-weight: 700;
  color: var(--color-light-black);
  margin: 30px 0 10px;
}

.custom-pricing .ant-input-number-input {
  text-align: center;
}

.price-card-btn {
  padding-top: 30px;
  text-align: center;
}

.discount-setting .ant-list:first-child .ant-list-item::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 4px;
  height: 4px;
  border-radius: 30px;
  background: var(--color-light-black);
}

.discount-setting .ant-list .ant-list-item .ant-typography {
  display: block;
  font-family: var(--font-sfpro);
  font-size: 18px;
  font-weight: 400;
  color: var(--color-light-black);
}

.discount-setting .ant-list:nth-child(2) .ant-list-item .ant-typography,
.discount-setting .ant-list:nth-child(3) .ant-list-item .ant-typography {
  margin-left: 0;
}

.discount-setting .ant-list:nth-child(2) .ant-list-item {
  justify-content: center;
}

.discount-setting .ant-list:nth-child(2) {
  border-radius: 10px;
  background: var(--color-white);
  padding: 0 20px;
}

.discount-setting .ant-input {
  font-family: var(--font-sfpro);
  font-size: 18px;
  font-weight: 500;
  color: var(--color-dark);
  border: 0;
  box-shadow: none;
  width: 100%;
  max-width: 60px;
  height: 28px;
  text-align: center;
  padding: 0;
}

/* Discount Setting */
.discount-list .ant-list {
  margin-right: 20px;
}

.discount-setting .ant-list .ant-list-item {
  display: flex;
  align-items: center;
}

.discount-list .ant-list .ant-list-item {
  justify-content: space-between;
  padding: 0;
}

.discount-list .ant-list .ant-list-item:first-child .ant-input {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.discount-list .ant-list .ant-list-item:last-child .ant-input {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.discount-list .ant-input {
  border-radius: 0px;
}

.discount-list .ant-list .ant-list-item::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 4px;
  height: 4px;
  border-radius: 30px;
  background: var(--color-light-black);
}

.discount-list h2.ant-typography {
  margin-bottom: 0;
}

.discount-input {
  text-align: center;
}

.discount-input .ant-input {
  max-width: 71px;
  width: 100%;
  height: 52px;
}

.discount-input .ant-form-item {
  margin-bottom: 0px;
}

.pricing-title .ant-list-item:not(:first-child) {
  height: 58px;
}

.pricing-label .ant-list-item {
  height: 58px;
}

/* MEDIA QUERIES */
@media (max-width: 1200px) {
  .custom-pricing .ant-row {
    flex-flow: row;
  }
}

@media (max-width: 767px) {
  .pricing-content .ant-list-item:nth-child(1) {
    padding: 8px 10px;
  }
}

@media (max-width: 576px) {
  .pricing-content .ant-list-item:nth-child(1) {
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .pricing-content .ant-list-item:nth-child(1) .ant-typography {
    font-size: 14px;
  }

  .pricing-content .ant-list-item .ant-typography {
    font-size: 14px;
    margin-left: 0;
  }

  .pricing-content .ant-list .ant-list-item:first-child {
    padding: 10px 10px;
    margin: 10px 10px 0;
    margin-bottom: 27px;
  }
}