.upload-box .ant-typography {
    display: block;
    font-family: var(--font-poppins);
    font-size: 20px;
    font-weight: 500;
    color: var(--color-dark-gray);
    margin-bottom: 15px;
}

.upload-box .ant-typography:nth-child(2) {
    font-size: 16px;
}

.upload-box .ant-btn {
    font-family: var(--font-mulish);
    font-size: 14px;
    font-weight: 700;
    color: var(--color-light-blue);
    background: transparent;
    border: 1px solid var(--color-light-blue);
    border-radius: 8px;
    padding: 12px 24px;
    height: initial;
    transition: all .4s;
}

.upload-box .ant-btn:hover {
    background: var(--color-light-blue);
    color: var(--color-white);
}



.upload-icon svg {
    height: 50px;
    width: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    border-radius: 50%;
    background-color: #3498db;
}

.loader {
    margin: 0 auto;
    border: 3px solid #f3f3f3;
    border-radius: 50%;
    border-top: 3px solid #3498db;
    width: 65px;
    height: 65px;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.modalbtn {
    float: right;
    color: red;
    cursor: pointer;
    font-weight: 500;
}

.modalbtn :hover {
    text-decoration: underline;
}