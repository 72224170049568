.add-new-user {
  padding-top: 30px;
}

.add-new-user .ant-typography {
  font-family: var(--font-mulish);
  font-size: 24px;
  font-weight: 700;
  color: var(--color-dark-blue);
  display: inline-block;
  margin-bottom: 30px;
  border-bottom: 1px solid var(--color-blue);
}

.add-new-user .ant-row {
    display: block;
}

.ant-form-item-with-help .ant-form-item-explain  {
  font-size: 13px;
  margin-bottom: 10px;
}

.add-new-user .ant-form-item .ant-form-item-label {
  text-align: start;
  font-family: var(--font-mulish);
  font-size: 16px;
  font-weight: bold;
  color: var(--color-dark-blue);
  margin-bottom: 0;
}

.add-new-user .ant-form-item {
  margin-bottom: 10px;
}

.add-new-user .ant-input {
  font-family: var(--font-poppins);
  font-size: 14px;
  font-weight: 500;
  color: var(--color-dark);
  height: 44px;
  border: 1px solid var(--color-light-blue);
  margin-top: -8px;
}

.add-new-user .ant-input:hover,
.add-new-user .ant-input:focus {
  border: 1px solid var(--color-light-blue);
  outline: none;
  box-shadow: none;
}

.add-user-btn {
  margin: 10px 0 0;
}

.add-new-user .ant-checkbox-wrapper span {
  font-family: var(--font-mulish);
  font-size: 16px;
  font-weight: 600;
  color: var(--color-dark);
}

.add-new-user .ant-checkbox .ant-checkbox-inner {
  width: 24px;
  height: 24px;
}

.add-new-user .ant-checkbox .ant-checkbox-inner:after {
  width: 7.714286px;
  height: 13.142857px;
}

.add-new-user .ant-checkbox-checked .ant-checkbox-inner {
  background: var(--color-blue);
  border-color: var(--color-blue);
}

.ant-form-item .ant-form-item-label >label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}

/* MEDIA QUERIES */
@media (max-width: 1024px) {
  .add-new-user {
    padding-top: 10px;
  }
}

@media (max-width: 576px) {
  .add-new-user .ant-form-item {
    margin-bottom: 10px;
  }

  .add-new-user .ant-form-item .ant-form-item-label {
    padding: 0;
  }

  .add-user-btn {
    margin: 20px 0 0;
  }

}
