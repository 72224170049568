.tos {
  height: 400px;
  overflow: scroll;
  overflow-x: hidden;
  padding: 5px;
  font-weight: 700;
}

.h-1 {
  line-height: 1.38;
  margin-top: 0pt;
  margin-bottom: 0pt;
}

.h-2 {
  list-style-type: lower-alpha;
  font-size: 12pt;
  font-family: Roboto, sans-serif;
  color: #000000;
  background-color: transparent;
  font-weight: 700;
  font-style: normal;
  font-variant: normal;
  text-decoration: none;
  vertical-align: baseline;
  white-space: pre;
}

.h-3 {
  font-size: 12pt;
  font-family: Roboto, sans-serif;
  color: #000000;
  background-color: transparent;
  font-weight: 700;
  font-style: normal;
  font-variant: normal;
  text-decoration: none;
  vertical-align: baseline;
  white-space: pre;
  white-space: pre-wrap;
}

.h-4 {
  font-size: 12pt;
  font-family: Roboto, sans-serif;
  color: #000000;
  background-color: transparent;
  font-weight: 700;
  font-style: normal;
  font-variant: normal;
  text-decoration: none;
  vertical-align: baseline;
  white-space: pre;
  white-space: pre-wrap;
}

.h-5 {
  margin-top: 0;
  margin-bottom: 0;
  padding-inline-start: 48px;
  font-weight: 700;
}

.h-6 {
  line-height: 1.38;
  text-align: center;
  margin-top: 0pt;
  margin-bottom: 0pt;
  font-weight: 700;
}

.h-7 {
  font-size: 18pt;
  font-family: Roboto, sans-serif;
  color: #000000;
  background-color: transparent;
  font-weight: 700;
  font-style: normal;
  font-variant: normal;
  text-decoration: none;
  vertical-align: baseline;
  white-space: pre;
  white-space: pre-wrap;
}

.h-8 {
  font-size: 13.999999999999998pt;
  font-family: Roboto, sans-serif;
  color: #000000;
  background-color: transparent;
  font-weight: 700;
  font-style: normal;
  font-variant: normal;
  text-decoration: none;
  vertical-align: baseline;
  white-space: pre;
  white-space: pre-wrap;
}

.h-9 {
  font-size: 13pt;
  font-family: Roboto, sans-serif;
  color: #000000;
  background-color: transparent;
  font-weight: 700;
  font-style: normal;
  font-variant: normal;
  text-decoration: none;
  vertical-align: baseline;
  white-space: pre;
  white-space: pre-wrap;
}

.h-10 {
  line-height: 1.38;
  margin-left: 36pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  font-weight: 700;
}