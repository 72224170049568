 .donut-chart-text {
   display: flex;
   align-items: center;
   justify-content: space-between;
 }

 .donut-chart-text .ant-typography {
   font-family: var(--sfpro);
   font-size: 16px;
   font-weight: 500;
   color: var(--color-dark-gray);
 }

 .custom-marker {
   display: flex;
   margin-top: -10px;
 }

 .custom-marker-number {
  font-size: 16px;
   padding: 5px;
   border-radius: 5px;
   color: #fff
 }


 .custom-marker-value {
   padding: 5px;
   font-size: 14px;
 }

 #chart .apexcharts-canvas {
   /* height: 300px !important; */
 }

 .donat-chart .apexcharts-legend {
   padding-left: 0 !important;
   padding-right: 0 !important;
   margin-left: -20px !important;
   /* top: 0 !important; */
 }


 @media(max-width: 767px) {
   .custom-marker {
     margin-top: -28px
   }

   .custom-marker-number {
     font-size: 14px;
   }

   .custom-marker-value {
     font-size: 12px;
   }
 }

 .apx-legend-position-right {
   /* right: 55px !important */
 }


 @media(max-width: 480px) {
  .custom-marker {
    margin-top: -20px
  }
 }
 /*

.donat-chart {
  margin-top: 25px;
}


.donat-chart .apexcharts-legend-marker {
  display: none !important;
}

.donat-chart .apexcharts-legend-series {
  margin-top: -6px !important;
}

.donat-chart .apexcharts-legend {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-left: -20px !important;
  /* top: 0 !important; */
 /* }

.donut-chart-text span:first-child {
  padding-left: calc(100% - 520px);
} */