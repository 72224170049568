.analysis-order {
  margin-top: 30px;
}

.analysis-order h5.ant-typography {
  font-family: var(--font-sfpro);
  font-size: 18px;
  font-weight: 700;
  color: var(--color-dark);
}

.analysis-order .ant-input {
  font-family: var(--font-mulish);
  font-size: 14px;
  font-weight: normal;
  color: var(--color-dark);
  height: 40px;
  margin-bottom: 30px;
}

.analysis-order .ant-input::placeholder {
  color: var(--color-gray);
}

.analysis-order h4.ant-typography {
  font-family: var(--font-sfpro);
  font-size: 22px;
  font-weight: 700;
  color: var(--color-dark);
  text-align: center;
}

.analysis-order
  .ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload.ant-upload-select {
  width: 100%;
  height: 100%;
}

.analysis-list .ant-typography {
  font-family: var(--font-mulish);
  font-size: 16px;
  font-weight: normal;
  color: var(--color-dark);
}

.analysis-list .ant-tag {
  padding: 20px;
  border-radius: 10px;
}

.analysis-list article.ant-typography {
  font-family: var(--font-mulish);
  font-size: 16px;
  font-weight: 700;
  color: var(--color-dark);
}

.analysis-list .ant-list .ant-list-item:last-child {
  border-bottom: 1px solid rgba(5, 5, 5, 0.06);
}

.analysis-list .ant-list-footer {
  text-align: center;
}

.analysis-list .ant-list-footer .ant-typography {
  padding: 7px 23px 9px;
}

.analysis-order h3.ant-typography {
  font-family: var(--font-poppins);
  font-size: 20px;
  font-weight: 700;
  color: var(--color-text);
  margin-bottom: 0;
}

.analysis-order .ant-list-item-meta-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.analysis-order .my-button.ant-typography {
  padding: 4px 20px 6px;
}

.analysis-order .pdf-list-data {
  margin-top: 40px;
}

/* MEDIA QUERIES */
@media (max-width: 1200px) {
  .analysis-list {
    margin: 12px 0 20px;
  }

  .analysis-order .pdf-list-data {
    margin-top: 20px;
  }
}

@media (max-width: 576px) {
  .analysis-list .ant-list .ant-list-item {
    flex-direction: column;
  }
}
