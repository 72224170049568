/* .scroll {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
} */

.user-pricing-card {
  background: var(--color-bg-blue);
  padding: 20px 20px;
  margin-top: 20px;
  border-radius: 25px;
}

.user-subs-plan .ant-list .ant-list-item:first-child {
  padding: 3px 10px;
  margin: 10px 10px 0;
  margin-bottom: 10px;
}

.user-pricing-card .discount-price {
  /* margin-bottom: 0; */
}

.client-pricing-title h1.ant-typography {
  font-family: var(--font-poppins);
  font-size: 24px;
  font-weight: 600;
  color: var(--color-dark-blue);
  margin: 40px 0 20px;
}

.user-subs-plan .ant-list-item:not(:first-child) .ant-typography {
  padding: 4px 20px 2px;
  border-radius: 8px;
  color: var(--color-white);
}

.user-subs-plan .ant-list-item {
  padding: 0.6rem 0;
}

@media (max-width: 1199px) {
  .user-pricing-card .ant-row {
    width: 130%;
  }
}

@media (max-width: 991px) {
  .user-pricing-card .ant-row {
    width: 150%;
  }
}

@media (max-width: 767px) {
  .user-pricing-card .ant-row {
    width: 180%;
  }
}

@media (max-width: 576px) {
  .user-pricing-card .ant-row {
    width: 300%;
  }

  .client-pricing-title h1.ant-typography,
  .custom-pricing h2.ant-typography {
    font-size: 18px;
  }

  .custom-pricing h2.ant-typography {
    margin-bottom: 17px;
  }

  .discount-price .ant-typography {
    font-size: 16px;
  }
}