:root {
  --color-dark: #1a1a1a;
  --color-light-black: #00000099;
  --color-dark-blue: #11142d;
  --color-blue: #1c69c2;
  --color-light-blue: #609be5;
  --color-blue-900: #212044;
  --color-bg-blue: #eaf6fc;
  --color-dark-gray: #939393;
  --color-light-gray: #e2e2e2;
  --color-gray: #9a9ab0;
  --color-white: #fff;
  --color-text: #40566d;
  --color-gray-400: #fafbfe;
  --color-gray-600: #8b8b8b;
  --color-gray-100: #f1f1f5;
  --font-poppins: "Poppins", sans-serif;
  --font-mulish: "Mulish", sans-serif;
  --font-sfpro: "SFProText";
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
}

body {
  font-family: var(--font-mulish);
  color: var(--color-dark);
}

/* COMMON STYLES */
.container {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
}



/* MIN CONTENT */
.main-content {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 0;
  padding-left: 251px;
  transition: all 0.4s;
  width: 100%;
  /* padding-top: 50px; */
}

.main-content.active {
  padding-left: 0;
  transition: all 0.4s;
}

.main {
  padding: 0 30px 50px;
}

/* MEDIA QUERIES */
@media (min-width: 1200px) {
  .container {
    max-width: 1440px;
  }
}

@media (max-width: 991px) {
  .main-content {
    padding-left: 0;
  }
}

@media (max-width: 576px) {
  .main {
    padding: 0 20px 30px;
  }
}
