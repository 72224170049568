/* LOGIN STYLE */
.auth-bg {
  background: url(../../../src/assets//images/login-bg.png) no-repeat center;
  width: 100%;
  height: 100vh;
  background-size: cover;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.auth-signup {
  background: var(--color-white);
  max-width: 720px;
  padding: 20px 0;
  border-radius: 30px;
  margin: 0 auto;
  margin-top: -70px;
}

.auth-signup .ant-select-selector {
  height: 45px !important;
}

.auth-signup .ant-select-selector input {
  height: 45px !important;
}

.auth-signup .ant-select-selection-placeholder {
  padding-top: 5px !important;
}

.auth-signup .ant-select-selection-item {
  padding-top: 5px !important;
}

.auth-logo {
  display: block;
  text-align: center;
  border-bottom: 2px solid var(--color-blue);
  padding-bottom: 10px;
}

.auth-logo .ant-image .ant-image-img {
  width: 100%;
}

.login-form {
  padding: 30px 50px 0;
  max-width: 720px;
}

.login-form-footer {
  padding: 10px 50px 0;
  text-align: center;
}

.login-form-toc {
  padding: 10px 50px 0;
}


.auth-signup .ant-form-item {
  margin-bottom: 5px;
}

.auth-signup .ant-form-item .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: "";
}

.login-form h4.ant-typography {
  font-family: var(--font-mulish);
  font-size: 22px;
  font-weight: 700;
  color: var(--color-dark);
  margin-bottom: 20px;
}

.login-form .ant-form-item-label {
  font-family: var(--font-mulish);
  font-size: 16px;
  font-weight: 700;
  color: var(--color-dark);
}

.login-form .ant-input {
  font-family: var(--font-mulish);
  font-size: 14px;
  font-weight: 500;
  color: var(--color-dark);
  height: 46px;
  border-color: var(--color-blue);
}

.login-form .ant-input-password {
  border-color: var(--color-blue);
}

.login-form .ant-select-selector {
  border-color: var(--color-blue) !important;
}

.login-form .ant-input-affix-wrapper {
  padding-top: 0;
  padding-bottom: 0;
}

.login-form .ant-form-item-control-input .ant-typography a {
  font-family: var(--font-mulish);
  font-size: 14px;
  font-weight: 700;
  color: var(--color-gray);
  display: block;
  text-align: end;
}

.login-form .ant-form-item-control-input .ant-typography a:hover {
  color: var(--color-light-blue);
}

.login-form .ant-checkbox-wrapper span {
  font-family: var(--font-mulish);
  font-size: 14px;
  font-weight: 700;
  color: var(--color-gray);
}

.login-form .ant-checkbox .ant-checkbox-inner {
  border: 2px solid var(--color-light-blue);
}

.login-form .ant-checkbox-wrapper a {
  color: var(--color-light-blue);
}

.login-form .ant-form-item-control-input-content .my-button.ant-typography {
  display: block;
  text-align: center;
}

.login-form h5.ant-typography {
  font-family: var(--font-mulish);
  font-size: 14px;
  font-weight: 400;
  color: var(--color-gray);
  margin-bottom: 0;
}

.login-form a.ant-typography {
  font-size: 14px;
  color: var(--color-light-blue);
  display: inline-block;
}

/* .login-form {
  max-height: 70vh;
  overflow-y: scroll;
} */

/* MEDIA QUERIES */
@media (max-width: 576px) {
  .login-form {
    padding: 30px 20px 0;
    max-height: 70vh;
    overflow: auto;
  }

  .auth-signup {
    padding: 10px 0px 2px;
    margin-top: -100px;

  }

  .login-form-footer {
    padding: 10px 20px 0;
  }

  .login-form-toc {
    padding: 10px 20px 0;
  }

}