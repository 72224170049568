.analysis-depth-tire {
  margin-top: 30px;
}

.analysis-depth-tire h1.ant-typography {
  font-family: var(--font-poppins);
  font-size: 32px;
  font-weight: 700;
  color: var(--color-dark-blue);
  text-align: center;
}

.effect-analysis {
  background: var(--color-bg-blue);
  padding: 30px 20px;
  margin-top: 20px;
  border-radius: 25px;
  overflow-x: scroll;
  white-space: nowrap;
}

.effect-analysis h2.ant-typography {
  font-family: var(--font-sfpro);
  font-size: 24px;
  font-weight: 700;
  color: var(--color-dark-blue);
  margin-bottom: 54px;
}

.effect-analysis h5.ant-typography {
  font-family: var(--font-sfpro);
  font-size: 16px;
  font-weight: 400;
  color: var(--color-dark);
}

.effect-analysis .ant-list-item {
  position: relative;
  margin-left: 18px;
}

.effect-analysis .ant-list-item::after {
  content: "";
  position: absolute;
  top: 40%;
  left: -12px;
  width: 4px;
  height: 4px;
  border-radius: 30px;
  background: var(--color-light-black);
}

.effect-analysis-card {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

}

.effect-analysis-card .ant-card {
  background: var(--color-white);
  /* flex: 1; */
  border-radius: 10px;
  max-width: 220px;
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
}

.effect-analysis-card .ant-card .ant-card-body {
  padding: 14px 20px;
}

.effect-analysis-card .ant-card .ant-card-head {
  padding: 8px 8px 0;
}

.effect-analysis-card .ant-card .ant-card-head-title {
  margin-bottom: 26px;
}

.effect-analysis-card .ant-card:not(:last-child) {
  margin-right: 10px;
}

.effect-analysis-card .ant-card-head-wrapper {
  flex-direction: column;
  align-items: initial;
}

.effect-analysis-card .ant-card-head-title .effect-analysis-card-header {
  padding: 8px 20px;
  border-radius: 10px;
}

.effect-analysis-card .ant-card h4.ant-typography {
  font-family: var(--font-sfpro);
  font-size: 20px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
  color: var(--color-white);
  text-align: center;
}

.effect-analysis .effect-analysis-card .ant-card-extra {
  margin-inline-start: 0;
}

.effect-analysis .effect-analysis-card .ant-card-extra h3.ant-typography {
  font-family: var(--font-sfpro);
  font-size: 18px;
  font-weight: 700;
  color: var(--color-dark);
  text-align: center;
  display: block;
}

.effect-analysis-card-content {
  text-align: center;
}

.effect-analysis-card .ant-card .ant-card-body .ant-btn {
  font-family: var(--font-sfpro);
  font-size: 14px;
  font-weight: 700;
  color: var(--color-blue);
  background: var(--color-gray-100);
  border: 0;
  border-radius: 20px;
  box-shadow: none;
}

.effect-analysis-card-button:not(:last-child) {
  margin-bottom: 20px;
  margin-top: 20px;
}

/* .effect-analysis-card-button:first-child {
  margin-top: 20px;
} */

.effect-analysis-card-button a {
  display: inline-block;
  font-family: var(--font-sfpro);
  font-size: 16px;
  font-weight: 600;
  color: var(--color-white);
  background: var(--color-light-blue);
  padding: 8px 28px;
  border-radius: 8px;
}

/* MEDIA QUERIES */
@media (max-width: 1200px) {
  .effect-analysis-card {
    /* flex-wrap: nowrap; */
    justify-content: center;

  }

  .analysis-depth-tire .effect-analysis .ant-row {
    flex-flow: row;
  }
}

@media (max-width: 767px) {
  .analysis-depth-tire h1.ant-typography {
    font-size: 24px;
  }
}

@media (max-width: 576px) {
  .effect-analysis-card .ant-card{
    max-width: 295px ;
  }
  .analysis-depth-tire h1.ant-typography {
    font-size: 18px;
  }

  .effect-analysis-card {
    margin-top: -2px;
  }

  .effect-analysis h2.ant-typography {
    font-size: 22px;
  }
}