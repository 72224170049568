/* SIDEBAR STYLE */
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  max-width: 251px;
  min-width: 251px;
  height: 100%;
  background-color: var(--color-white);
  padding: 20px 25px 0;
  transition: 0.3s all;
  z-index: 101;
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  -webkit-transition: 0.3s all;
  -moz-transition: 0.3s all;
  -ms-transition: 0.3s all;
  -o-transition: 0.3s all;
  border-right: 1px solid var(--color-light-gray);
}

.sidebar.active {
  transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
}

.toggle-menu {
  display: none;
}

.sidebar .ant-menu-inline .ant-menu-item {
  width: initial;
}

.sidebar .ant-menu .ant-menu-item .ant-menu-item-icon + span {
  margin-inline-start: 0;
}

.sidebar .ant-image-img {
  width: 100%;
  height: 100%;
}

.sidebar .ant-typography {
  font-family: var(--font-poppins);
  font-size: 12px;
  font-weight: 700;
  color: var(--color-dark-gray);
  display: block;
  /* padding-top: 30px; */
}

.sidebar .side-menu {
  padding-left: 12px;
  padding-top: 20px;
}

.sidebar .ant-menu {
  padding-top: 15px;
}

.sidebar .ant-menu-light.ant-menu-root.ant-menu-inline {
  border-inline-end: none;
}

.sidebar .ant-menu .ant-menu-item .ant-menu-title-content {
  font-family: var(--font-poppins);
  font-size: 14px;
  font-weight: 600;
  display: block;
  padding-left: 20px;
  color: var(--color-dark-gray);
}

.sidebar .ant-menu .ant-menu-item {
  padding: 0 !important;
  margin: 0 0 20px;
}

.sidebar .ant-menu-light .ant-menu-item-selected {
  background: transparent;
}

.sidebar .ant-menu-light .ant-menu-item-selected .ant-menu-title-content {
  color: var(--color-blue);
}

.sidebar .ant-menu .ant-menu-item:hover .ant-menu-title-content {
  color: var(--color-blue);
}

.sidebar
  .ant-menu-light:not(.ant-menu-horizontal)
  .ant-menu-item:not(.ant-menu-item-selected):hover {
  background: transparent;
}

.sidebar .ant-menu-light .ant-menu-item-selected svg path {
  fill: var(--color-blue);
  stroke: var(--color-blue);
}

.sidebar .ant-menu .ant-menu-item:hover svg path {
  fill: var(--color-blue);
  stroke: var(--color-blue);
}

.sidebar .ant-menu-light .ant-menu-item-selected .user-item svg path,
.sidebar .ant-menu-light .ant-menu-item:hover .user-item svg path,
.sidebar .ant-menu-light .ant-menu-item-selected .results-item svg path,
.sidebar .ant-menu-light .ant-menu-item:hover .results-item svg path {
  fill: var(--color-blue);
  stroke: transparent;
}

.sidebar .ant-menu-light .ant-menu-item-selected .account-item svg path,
.sidebar .ant-menu-light .ant-menu-item:hover .account-item svg path,
.sidebar .ant-menu-light .ant-menu-item-selected .logout-item svg path,
.sidebar .ant-menu-light .ant-menu-item:hover .logout-item svg path {
  stroke: var(--color-blue);
  fill: transparent;
}

.sidebar .ant-menu .ant-menu-item:hover svg path {
  fill: var(--color-blue);
  stroke: var(--color-blue);
}

@media (max-width: 991px) {
  .toggle-menu {
    display: block;
    position: absolute;
    right: -54px;
    top: 26px;
    cursor: pointer;
  }

  .sidebar {
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
  }

  .sidebar.active {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
  }
}

@media (max-width: 576px) {
  .toggle-menu {
    right: -45px;
  }
}
