.new-analysis-order {
  margin-top: 30px;
}

.new-analysis-order h1.ant-typography {
  font-family: var(--font-poppins);
  font-size: 30px;
  font-weight: 700;
  color: var(--color-dark-blue);
  text-align: center;
}

.new-analysis-order .my-link {
  font-family: var(--font-poppins);
  font-size: 16px;
  font-weight: 600;
  padding: 8px 15px;
  border-radius: 8px;
  display: inline-block;
}

.new-analysis-order h2.ant-typography {
  font-family: var(--font-poppins);
  font-size: 26px;
  font-weight: 700;
  color: var(--color-dark-blue);
  text-align: center;
  margin-top: 20px;
  margin-bottom: 30px;
}

.purchase-cradit .ant-row {
  margin-top: -30px;
}

.purchase-cradit .ant-card {
  height: 100%;
  border: 1px solid #3498db9c;

}

.purchase-cradit .ant-col {
  margin-top: 24px;
}

.purchase-cradit .ant-card-head {
  padding: 20px 0px;
  border-bottom: 2px solid #cccccc87 !important;
}

.purchase-cradit h5.ant-typography {
  font-family: var(--font-poppins);
  font-size: 18px;
  font-weight: 600;
  color: var(--color-blue-900);
  margin-top: 0;
  margin-bottom: 0;
}

.purchase-cradit .ant-card .ant-card-head-wrapper {
  flex-direction: column;
}

.purchase-cradit .ant-card .ant-card-extra {
  margin-inline-start: 0;
  display: flex;
  margin-top: 10px;
  align-items: baseline;
}

.purchase-cradit .ant-card .ant-card-extra h1.ant-typography {
  font-family: var(--font-poppins);
  font-size: 32px;
  font-weight: 700;
  /* color: var(--color-white); */
  margin-bottom: 0;
}

.purchase-cradit .ant-card .ant-card-extra span.ant-typography {
  font-family: var(--font-mulish);
  font-size: 16px;
  font-weight: normal;
  color: var(--color-blue-900);
  /* align-items: baseline; */
  margin-left: 10px;
}

.card-content-outer {
  min-height: 85px;
}

.purchase-cradit .ant-card .ant-card-body .card-content {
  display: flex;
  align-items: center;
}

.purchase-cradit .ant-card .ant-card-body .card-content:not(:last-child) {
  margin-bottom: 20px;
}

.purchase-cradit .ant-card .ant-card-body .card-content h4.ant-typography {
  font-family: var(--font-sfpro);
  font-size: 16px;
  font-weight: 600;
  color: var(--color-blue-900);
  margin-right: 10px;
  margin-bottom: 0;
}

.purchase-cradit .ant-card .ant-card-body .card-content .ant-btn {
  font-family: var(--font-sfpro);
  font-size: 14px;
  font-weight: 700;
  color: var(--color-blue);
  background: var(--color-gray-100);
  border: 0;
  border-radius: 20px;
  box-shadow: none;
}

.purchase-cradit .ant-card-body {
  padding: 20px 0;
}


.purchase-cradit .ant-card .card-bottom-content {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 30px;
}

.purchase-cradit .ant-card .card-bottom-content .my-link:not(:last-child) {
  margin-bottom: 10px;
}

.purchase-list-text {

  padding-left: 50px;
}


.experience {
  padding: 8px;
  /* border: 1px solid #3498db9c; */
  border-radius: 10px;
  width: 180px;
  margin-top: 30px;
  margin-bottom: 18px;
}

.experience span {
  line-height: 1.5335
}

.purchase-list-text li {
  border: none;
  border-block-end: none !important;
  padding-bottom: 0px !important;
}

.purchase-list-text li span {
  font-weight: 600;
  font-size: 17px;
}


.purchase-list-text-last{
  padding-left: 20px;
}
.bulk-discount-list li {
  color: #212044;
  font-size: 19px;
  font-weight: 400;
}

/* MEDIA QUERIES */
@media (max-width: 1200px) {
  .purchase-cradit .ant-col {
    margin-bottom: 20px;
  }

  .analysis .ant-col {
    margin-bottom: 40px !important
  }
}

@media (max-width: 576px) {

  .new-analysis-order h2.ant-typography,
  .new-analysis-order h1.ant-typography {
    font-size: 20px;
  }

  .analysis .ant-col {
    margin-bottom: 40px
  }

  .analysis-header {
    flex: 100% !important;
  }

  .analysis {
    justify-content: center;
  }

  .new-analysis-order {
    width: 100% !important
  }

  .purchase-list-text{
    padding-left: 30px;
  }
  .purchase-list-text-last{
    padding-left: 30px;
  }
  .purchase-list-text li span{
    font-size: 26px;
  }
  .experience{
    width: 100%;
  }
}

.new-analysis-order {
  width: 879px
}


.footer-btn {
  position: absolute;
  bottom: 30px;
  text-align: center;
  /* vertical-align: middle; */
  margin: 0 auto;
  width: 80%;
}

@media (max-width: 768px) {

  .analysis {
    justify-content: center;
  }

  .new-analysis-order {
    width: 100%
  }

  .purchase-list-text{
    padding-left: 0px;
  }
  .purchase-list-text-last{
    padding-left: 0px;
  }
  .new-analysis-order .my-link{
    font-size:14px
  }
  
}

@media screen and (min-width: 1900px) {
  .new-analysis-order {
    width: 879px
      /* width: 50% !important */
  }
}


.list-header-top {
  margin-top: -52px;

}

.list-header-top-border {
  border-radius: 50px;
  border: 5px solid #32105d;
  width: 100px;
  height: 100px;
  background: linear-gradient(#59cab9, #4786ef);
  /* border-top-color: transparent; */
  /* border-left-color: transparent; */
  /* transform: rotate(43deg); */
  position: relative;

  margin: 0 auto;
}

.analysis-header {
  background: #32105d;
  padding: 25px;
  border-radius: 25px;
  box-shadow: 1px 2px 10px 10px #ccc
}

/* .list-header-top-border::before {
  content: "";
  position: absolute;
  border-radius: 50px;
  top: 0;
  left: 0;
  border-bottom: 5px solid black;
  border-left: 5px solid black;
  width: 100px;
  height: 100px;
} */

.analysis .ant-list-header {
  display: flex;
  justify-content: center;
  border: none;
}

.analysis .ant-typography {
  color: #fff;
}

.analysis .ant-list-item .ant-typography {
  color: #fff !important;
}

.analysis .ant-list {
  text-align: center;
  border-radius: 20px !important;
}


.analysis .ant-list .ant-list-item {
  /* padding-left: 45px !important; */
  justify-content: left !important;
  padding: 3px 0px 0px 30px !important;
}

.analysis .ant-list .ant-list-item .ant-typography {
  font-weight: 400 !important;
}

.analysis .ant-list-split .ant-list-item {
  border: none;
}

.analysis .ant-spin-nested-loading {
  padding-bottom: 15px;
}

.analysis .card-bottom-content {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.analysis .card-bottom-content a {
  padding: 8px;
  margin-top: 8px;
  border-radius: 15px;
}

.pricedollar {
  position: absolute;
  /* padding: 11px; */
  font-size: 17px !important;
  margin-top: 19px;
  margin-left: 7px;
  font-weight: 700 !important;
}

.analzie-coas {
  color: rgb(255, 255, 255);
  font-weight: 700;
  font-size: 17px;
}


.analzie-coas2:hover {
  color: #fff !important;
  transition: color 0.3s
}

.analzie-coas3:hover {
  color: #fff !important;
  transition: color 0.3s
}

.analysis-header {
  max-width: 293px;
  margin: 30px 15px 0px 15px;
  flex: 32%
}

.card-bottom-content a {
  cursor: pointer;
}