.orders {
  margin-top: 30px;
}

.orders .ant-list-item:nth-child(even) {
  background-color: #FAFBFE;
}

.orders .order-count {
  flex: 0 0 1%;
}

.orders .ant-list-item-meta {
  flex: 0 0 50% !important;
}

.orders .order-btn {
  flex: 0 0 10%;
}

.orders .ant-list-item-action {
  flex: 0 0 24% !important;
  margin-inline-start: initial !important;
}

.orders h5.ant-typography {
  font-family: var(--font-mulish);
  font-size: 20px;
  font-weight: 800;
  color: var(--color-text);
}

.orders .ant-list .ant-list-item .ant-list-item-meta .ant-list-item-meta-title {
  margin: 0 0 1px 0;
}

.orders .ant-list .ant-list-item .ant-list-item-meta .ant-list-item-meta-avatar {
  margin-inline-end: 6px;
}

.orders .ant-list .ant-list-item {
  align-items: center;
  padding: 3px 24px;
}

.orders .ant-list .ant-list-item .ant-list-item-meta .ant-list-item-meta-title>a {
  display: block;
  font-family: var(--font-poppins);
  font-size: 14px;
  font-weight: 600;
  color: var(--color-light-blue);
}

.orders .ant-list .ant-list-item-meta-description article.ant-typography {
  font-family: var(--font-poppins);
  font-size: 12px;
  font-weight: 600;
  color: var(--color-dark-gray);
  margin-left: -22px;
}

.orders .ant-list-item-action .ant-tag {
  font-family: var(--font-poppins);
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
}

.orders .ant-list .ant-list-item .ant-list-item-action .ant-typography {
  font-family: var(--font-poppins);
  font-size: 14px;
  font-weight: 500;
  color: var(--color-dark);
  padding: 0 6px !important;
}

.orders .ant-list-footer {
  border-top: 1px solid rgba(5, 5, 5, 0.06);
  text-align: center;
}

.orders .ant-list-footer .ant-btn {
  font-family: var(--font-mulish);
  font-size: 16px;
  font-weight: 600;
  color: var(--color-text);
  background: transparent;
  border: 0;
  box-shadow: none;
}

.orders .order-button {
  font-family: var(--font-poppins);
  font-size: 14px;
  font-weight: 600;
  color: #696974;
  background: var(--color-light-gray);
  border: 0;
  border-radius: 10px;
  box-shadow: none;
  padding: 3px 12px 4px;
}

@media(max-width: 576px) {
  .orders .order-count {
    margin-top: 5px;
  }

  .orders .ant-list-item-meta {
    flex: 0 0 auto !important;
    width: 100%;
    margin-left: 0;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .orders .ant-list .ant-list-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .orders .ant-list-item-action {
    margin-left: 0;
    margin-top: 15px;
    margin-bottom: 10px;
  }

  .searchBar {
    display: block !important;
  }

  .searchInput {
    width: 100% !important;
  }

}

.searchBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.searchInput {
  width: 50%;
}