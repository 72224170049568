@font-face {
  font-family: "SFProText";
  src: url("../src/fonts/SFProText-Heavy.woff2") format("woff2"),
    url("../src/fonts/SFProText-Heavy.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SFProText";
  src: url("../src/fonts/SFProText-Black.woff2") format("woff2"),
    url("../src/fonts/SFProText-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SFProText";
  src: url("../src/fonts/SFProText-Bold.woff2") format("woff2"),
    url("../src/fonts/SFProText-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SFProText";
  src: url("../src/fonts/SFProText-Light.woff2") format("woff2"),
    url("../src/fonts/SFProText-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SFProText";
  src: url("../src/fonts/SFProText-Medium.woff2") format("woff2"),
    url("../src/fonts/SFProText-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SFProText";
  src: url("../src/fonts/SFProText-Regular.woff2") format("woff2"),
    url("../src/fonts/SFProText-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SFProText";
  src: url("../src/fonts/SFProText-Semibold.woff2") format("woff2"),
    url("../src/fonts/SFProText-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}


/* @media only screen and (max-width: 600px) {
  body {
    overflow: hidden;
  }
} */

.stripSeal {
  width: 68%;
  float: right;
  padding-top: 10px;
}

.StripeElementa {
  margin-top: 15px !important;
  border: 1px solid #e6e6e6;
  padding: 15px;
  border-radius: 6px;
}


::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #d9d9d9;
  border-radius: 10px;

}

::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 10px;

}

.login-footer {
  height: 65px;
  background: #609be5;
  position: fixed;
  bottom: 5px;
  width: 98%;
  border-radius: 8px;
  /* padding: 10px 215px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-footer h3 {
  font-weight: 300;
  margin: 0;
}

.footerBtn {
  /* float: right; */
}

.loginUpbtn:hover {
  background-color: #fff;
  color: #609be5;
}

.loginUpbtn {
  background-color: #609be5;
  color: #fff;
  border-radius: 8px;
}


.signUpbtn {

  background-color: #fff;
  color: #609be5;
  font-weight: 700;
  margin-right: 10px;
  border-radius: 8px;

}

.footer-title {
  display: flex;
  margin: 0;
  width: 50%;
  flex-direction: row;

}

.containerCard {
  border: 1px solid #cccc;
  padding: 15px;
  width: 507px;
  border-radius: 15px;
}

.my-button {
  font-family: var(--font-mulish);
  font-size: 16px;
  font-weight: 700;
  padding: 12px 23px;
  border-radius: 8px;
  display: block;
  width: 100%;
  box-shadow: none;
  border: 0;
  height: initial;
}

@media (max-width: 768px) {
  .login-footer {
    height: 70px;
    /* padding: 10px 61px; */
  }

  .login-footer h3 {
    font-size: 20px;
  }

  .footer-title {
    flex-direction: column;
    /* width: 35%; */
  }

  .containerCard {
    width: 100% !important;
  }

  .stripSeal {
    width: 60%;
    padding-top: 5px;
  }

  .signUpbtn,
  .loginUpbtn {
    height: 45px !important;
    font-size: 20px;
  }



}

@media (max-width: 576px) {
  .login-footer {
    height: 70px;
    /* padding: 10px 5px !important; */
  }

  .login-footer h3 {
    margin-top: 5px;
    font-size: 18px !important;
  }

  .footer-title {
    flex-direction: column;
    width: 45%;
  }

  .containerCard {
    width: 100% !important;
  }


  .my-button {
    font-size: 13px;
    padding: 7px 11px;
  }

  .stripSeal {
    width: 65%;
    padding-top: 10px
  }
}