.bar-cahrt {
    box-shadow: 0px 0 10px 5px rgba(0, 0, 0, 0.03);
    border-radius: 10px;
}

.apexcharts-toolbar{
    left: 0px !important;
}


@media (max-width: 767px) {
    .bar-cahrt {
        width: 100% !important;
        overflow-y: hidden;
        overflow-x: auto;
    
    }
  }
