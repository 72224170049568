.modal-header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-header h5.ant-typography {
  font-family: var(--sfpro);
  font-size: 24px;
  font-weight: 700;
  color: var(--color-dark);
  margin-bottom: 0;
  margin-top: 16px;
  margin-left: 10px;
}

.ant-modal .ant-modal-content {
  background-color: #fff;
  border-radius: 25px;
}

.modal-body {
  margin: 30px 0;
}

.modal-body .ant-list {
  text-align: center;
  border-radius: 10px;
}

.modal-body .modal-list-header h4.ant-typography {
  font-family: var(--sfpro);
  font-size: 24px;
  font-weight: 700;
  color: var(--color-dark);
  margin-bottom: 0;
  margin-top: 0;
}


.modal-body .ant-list .ant-list-item {
  justify-content: center;
}

.modal-body .ant-list .ant-list-item .ant-typography {
  font-family: var(--sfpro);
  font-size: 16px;
  font-weight: 500;
  color: var(--color-dark);
}

.modal-body .modal-list-header {
  margin-bottom: 20px;
}

/* .modal-body .ant-list:nth-child(even) .ant-list-item .ant-typography {
    color: white;
} */

/* MEDIA QUERIES */
@media (max-width: 1200px) {
  .modal-body .ant-col {
    margin-bottom: 20px;
  }

  .modal-header {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 576px) {
  .modal-header {
    flex-direction: column;
  }

  .modal-header h5.ant-typography {
    font-size: 18px;
  }
}