.add-new-user .ant-typography {
  font-family: var(--font-mulish);
  font-size: 24px;
  font-weight: 700;
  color: var(--color-dark-blue);
  display: inline-block;
  margin-bottom: 30px;
  border-bottom: 1px solid var(--color-blue);
}

.add-new-user .ant-form-item .ant-form-item-label {
  text-align: start;
  font-family: var(--font-mulish);
  font-size: 16px;
  font-weight: bold;
  color: var(--color-dark-blue);
  margin-bottom: 6px;
}

.add-new-user .ant-input {
  font-family: var(--font-poppins);
  font-size: 14px;
  font-weight: 500;
  color: var(--color-dark);
  height: 44px;
  border: 1px solid var(--color-light-blue);
}

.add-new-user .ant-input:hover,
.add-new-user .ant-input:focus {
  border: 1px solid var(--color-light-blue);
  outline: none;
  box-shadow: none;
}

.add-new-user .ant-checkbox-wrapper span {
  font-family: var(--font-mulish);
  font-size: 16px;
  font-weight: 600;
  color: var(--color-dark);
}

.add-new-user .ant-checkbox .ant-checkbox-inner {
  width: 24px;
  height: 24px;
}

.add-new-user .ant-checkbox .ant-checkbox-inner:after {
  width: 7.714286px;
  height: 13.142857px;
}

.add-new-user .ant-checkbox-checked .ant-checkbox-inner {
  background: var(--color-blue);
  border-color: var(--color-blue);
}

.user-pricing-title h1.ant-typography {
  font-family: var(--font-mulish);
  font-size: 24px;
  font-weight: 700;
  color: var(--color-dark);
  display: inline-block;
  border-bottom: 1px solid var(--color-blue);
  margin: 20px 0;
}

@media (max-width: 576px) {
  .add-new-user .ant-form-item {
    margin-bottom: 10px;
  }

  .add-new-user .ant-form-item .ant-form-item-label {
    margin-bottom: 0;
    padding: 0;
  }

  .add-user-btn {
    margin: 20px 0 0;
  }

  .user-pricing-title h1.ant-typography {
    font-size: 18px;
  }
}
