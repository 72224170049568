.deliver-order {
  margin-top: 30px;
}

.deliver-order .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select {
  width: 100%;
  height: 100%;
  border: 3px dashed #d9d9d9;
  border-radius: 1px;
}

.deliver-order h4.ant-typography {
  font-family: var(--font-poppins);
  font-size: 20px;
  font-weight: 600;
  color: var(--color-text);
  margin-bottom: 15px;
  text-align: center;
}

.deliver-order .ant-typography.my-button {
  text-align: center;
  margin-top: 25px;
}

.upload-card h4.ant-typography {
  padding: 20px;
  border: #D9E0E9 1px solid;
  margin-bottom: 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.upload-file {
  background-color: rgba(0, 0, 0, .03);
  padding: 10px;
}

.upload-file .ant-upload {
  margin-bottom: 0 !important;
  background-color: white !important;
}

.upload-file .ant-upload:hover {
  border-color: #609BE5 !important;
}

/* MEDIA QUERIES */
@media (max-width: 1024px) {
  .deliver-order .ant-col {
    margin-bottom: 20px;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .deliver-order {
    margin-top: 10px;
  }
}

@media (max-width: 769px) {

  .stacked-chart-selection {
    margin-top: 50px !important;
  }
}

@media (max-width: 576px) {
  .deliver-order h3.ant-typography {
    font-size: 18px;
  }

  .deliver-order .ant-row {
    display: inline-block;
    width: 100%;
  }

  .list-header {
    flex-direction: column;
  }

  .deliver-order h5.ant-typography {
    margin-right: 0;
  }

  .deliver-order h4.ant-typography {
    margin-top: 20px;
  }

  .stacked-chart h5.ant-typography {
    font-size: 15px !important;
  }

  .donut-chart-text .ant-typography {
    font-size: 13px !important;

  }
  .stacked-chart-selection{
    margin-top: 20px !important;
  }
}