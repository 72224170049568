/* HEADER STYLE */
.header {
  position: sticky;
  top: 0;
  z-index: 1;
  background: white;
  height: 70px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 0 30px;
  z-index: 100;
}

.header .navbar-right .ant-dropdown-trigger {
  display: block;
}

.header .ant-typography {
  font-family: var(--font-poppins);
  font-size: 24px;
  font-weight: 600;
  color: var(--color-dark);
  margin-bottom: 0;
}

.header .ant-space-item {
  font-family: var(--font-poppins);
  font-size: 14px;
  font-weight: 600;
  color: var(--color-dark);
}

.header .ant-space {
  gap: 10px !important;
}

.header .ant-image-img {
  object-fit: cover;
  border-radius: 10px;
}

/* MEDIA QUERIES */
@media (max-width: 1024px) {
  .header .ant-typography {
    font-size: 18px;
  }
}

@media (max-width: 991px) {
  .header {
    padding-left: 80px;
  }
}

@media (max-width: 576px) {
  .header {
    padding-right: 20px;
  }

  .header .ant-typography {
    font-size: 14px;
  }
  .navbar-left{
    width: 70%;
  }

  .header .navbar-right .ant-space {
    align-items: flex-end;
    /* display: none; */
  }

}