.chart-table {
  margin-top: 30px;
}

.chart-table .ant-table .ant-table-cell {
  font-family: var(--font-poppins);
  font-size: 12px;
  font-weight: 600;
  color: #000000a1 !important;
  text-align: center;
}

.chart-table .ant-table-thead .ant-table-cell:first-child,
.chart-table .ant-table-body .ant-table-cell:first-child {
  text-align: left;
}

.chart-table .ant-table-thead .ant-table-cell:nth-child(2),
.chart-table .ant-table-body .ant-table-cell:nth-child(2) {
  text-align: left;
}

.chart-table .ant-table .ant-table-cell {
  padding: 6px 10px;
  /* border: 1px solid #E3E9EF; */
}

.chart-table .ant-table .ant-table-thead .ant-table-cell {
  background-color: white;
}

.chart-table .ant-table .ant-table-thead .ant-table-cell:before {
  display: none;
}

.chart-table .ant-table .ant-table-body tr:nth-child(odd) {
  background: #FAFBFE;
}

.chart-table .ant-table .ant-table-body tr:nth-child(odd) .ant-table-cell:first-child {
  border-left: 1px solid #E3E9EF;
}

.chart-table .ant-table .ant-table-body tr:nth-child(odd) .ant-table-cell:last-child {
  border-right: 1px solid #E3E9EF;
}

.serach {
  width: 50%;
}

/* MEDIA QUERIES */
@media (max-width: 676px) {
  .chart-table {
    margin-top: 0;
  }

  .serach {
    width: 100%;
  }
}