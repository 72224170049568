.start-orders {
  margin-top: 30px;
}

.start-orders h5.ant-typography {
  font-family: var(--font-sfpro);
  font-size: 18px;
  font-weight: 700;
  color: var(--color-dark);
}

.start-orders .ant-input {
  font-family: var(--font-mulish);
  font-size: 14px;
  font-weight: normal;
  color: var(--color-dark);
  height: 40px;
  margin-bottom: 30px;
}

.start-orders .ant-input::placeholder {
  color: var(--color-gray);
}

.start-orders h4.ant-typography {
  font-family: var(--font-sfpro);
  font-size: 22px;
  font-weight: 700;
  color: var(--color-dark);
  text-align: center;
}

.start-orders
  .ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload.ant-upload-select {
  width: 100%;
  height: 100%;
}

.start-orders .ant-list-item-meta-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.start-orders .my-button.ant-typography {
  padding: 4px 20px 6px;
}

.start-orders .pdf-list-data {
  margin-top: 40px;
}

.start-order-list .ant-typography {
  font-family: var(--font-mulish);
  font-size: 16px;
  font-weight: normal;
  color: var(--color-dark);
}

.start-order-list article.ant-typography {
  font-family: var(--font-mulish);
  font-size: 16px;
  font-weight: 700;
  color: var(--color-dark);
}

.start-order-list .ant-list .ant-list-item:last-child {
  border-bottom: 1px solid rgba(5, 5, 5, 0.06);
}

.start-order-list .ant-list-footer {
  padding: 0 30px 20px;
}

.start-order-list .list-footer {
  display: flex;
  flex-direction: column;
}

.start-order-list .list-footer .my-button.ant-typography {
  margin-bottom: 20px;
  width: 46%;
  margin: 30px auto;
  text-align: center;
}

.start-order-list .list-footer .ant-typography {
  font-size: 14px;
  font-weight: 600;
}

.list-footer .ant-btn {
  margin: 20px 0;
}

/* MEDIA QUERIES */
@media (max-width: 1200px) {
  .start-orders .pdf-list-data {
    margin-top: 20px;
  }

  .start-order-list .ant-list-footer {
    padding: 0 40px 20px;
  }

}

@media (max-width: 576px) {
    .start-order-list .list-footer .my-button.ant-typography {
        width: 100%;
    }
}
